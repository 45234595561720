// Applies a CSS reset to the whole application for maximum cross-browser compatibility
// Katal styling will override this when loaded.
@use 'reset-css';
@use '~@amzn/sss-website-theme/dist/theme/variables.module.scss' as theme;
@use './utility' as utils;
@use './variables' as variables;

:global html {
  background: theme.$grey-secondary;
  font-family: 'Amazon Ember', Helvetica, Arial, sans-serif;
  &.no-scroll {
    overflow: hidden;
  }
}

// We're setting a z-index on these because using `position: sticky` creates a new stacking context, and we are unable
// to rearrange the dom since these elements are injected on the page by Mons
:global #sc-navbar-container {
  min-height: variables.$headerMinHeight;
  position: sticky;
  top: 0;
  z-index: 9999;
  transition: transform 0.4s ease-in-out;
  &.hide {
    transform: translateY(-100%);
  }

  &.show {
    transform: translateY(0);
  }

  @include utils.device-view {
    min-height: variables.$headerMinHeightDevice;
  }
}

:global #sc-content-container {
  min-height: calc(100vh - #{variables.$headerMinHeight} - #{variables.$footerMinHeight});
  z-index: 0;

  @include utils.device-view {
    min-height: calc(100vh - #{variables.$headerMinHeightDevice} - #{variables.$footerMinHeight});
  }
}

:global #sc-footer-container {
  min-height: variables.$footerMinHeight;
}
