@use '~@amzn/sss-website-theme/dist/theme/variables.module.scss' as theme;
@use 'src/styles/variables' as variables;
@use 'src/styles/utility' as utils;

.buttonLink {
  background-color: theme.$green-primary;
  color: theme.$grey-secondary !important;
  text-decoration: none;
  border-radius: theme.$border-radius-medium;
  display: flex;
  height: 64px;
  width: 292px;
  align-items: center;
  justify-content: space-between;

  @include utils.desktop-view-up {
    width: 260px;
  }

  @include utils.desktop-view-large-up {
    width: 292px;
  }

  &.dark {
    &:not(.tertiaryButtonLink) {
      background-color: rgba(theme.$grey-tertiary, 0.05)!important;
      color: theme.$white !important;
  
      &:hover {
        background-color: theme.$green-primary !important;
      }
    }
  }

  &:hover {
    background-color: theme.$slate-dark;
  }

  &.secondaryButtonLink {
    &:not(.dark) {
      background-color: theme.$green-secondary !important;
      color: theme.$green-primary !important;

      &:hover {
        background-color: theme.$grey-secondary !important;
      }
    }
  }

  &.tertiaryButtonLink {
    width: 199px;
    height: 24px;
    border-radius: initial;
    background-color: transparent;
    color: theme.$green-secondary !important;

    @include utils.device-view {
      margin-top: 24px;
      margin-bottom: 24px;
    }

    &.dark {
      span {
        border-bottom: 2px solid theme.$green-secondary;
      }
    }
  }

  .buttonText {
    margin-left: 26px;

    &.buttonTextTertiary {
      margin: 0;
    }
  }
}

.homeIcon {
  @extend .icon;
  height: variables.$headerIconSize !important;
  width: variables.$headerIconSize !important;
  path {
    fill: theme.$grey-secondary;
  }

  &.homeIconSecondary {
    &:not(.dark) {
      path {
        fill: theme.$green-primary;
      }
    }
  }

  &.homeIconTertiary {
    @extend .iconSecondary;
    background-color: theme.$green-primary;
    &.dark {
      background-color: theme.$slate-dark;
    }
  }
}

.iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  width: 75px;
  background-color: rgba(theme.$white, 0.05);
  border-radius: 100px;
  margin-right: 4px;

  @include utils.desktop-view-up {
    width: 56px;
  }

  @include utils.desktop-view-large-up {
    width: 75px;
  }

  &.secondaryIconContainer {
    &:not(.dark) {
      background-color: rgba(theme.$green-primary, 0.05);
    }
  }

  &.tertiaryIconContainer {
    width: variables.$headerIconSize;
    height: variables.$headerIconSize;
    &.dark {
      background-color: none;
    }
  }
}
